@import "variables";




.whole {
  background: #fafafa;
  padding: .5em;
}

.pane {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 1em;
  padding: 1em;

  &:not(:first-child){
    margin-left: 1em;
  }
}

.selected-answer {
  border-width: 2px;
  font-weight: bold;
}

// Vuejs specific classes
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


/* display breakpoints, going down
=================================== */

// on extra small screens (lesser than sm)
@media (max-width: $xs-max){
    .hide-lt-sm { display: none; }
}
// on screens lesser than md
@media (max-width: $sm-max){
    .hide-lt-md { display: none; }
}
// on screens lesser than lg
@media (max-width: $md-max){
    .hide-lt-lg { display: none; }
}
// on screens lesser than xl
@media (max-width: $lg-max){
    .hide-lt-xl { display: none; }
}
